import "twin.macro";
import AboutImg from "../images/AboutUs/about-img.png";
import AboutBack from "../images/AboutUs/back.png";
import retailShop from "../images/AboutUs/retailShop.webp";
import silks from "../images/AboutUs/silks.jpg";
import members from "../images/AboutUs/members-banner.jpg";
import { memo } from "react";

function AboutUs({ branch }) {
  return (
    <>
      <div className="container" tw="lg:py-16 py-10 ">
        <div className="row align-items-center flex-column-reverse flex-lg-row py-2">
          <div className="col-md-12 col-lg-6">
            <h1 tw="text-5xl my-5 lg:mt-2">WHO WE ARE!!</h1>
            <div className="ptSans text-base">
              <p tw="text-zinc-300 ">
                {branch.storeName}   We are a premier Gold  jewellery showroom located in the heart of Coimbatore catering its customers since 1999. Our showroom and customers has grown in manifolds catering wide range of domestic and international customers.

                Today we provide wide range of services like sales of Gold and Silver jewellery, articles and gifts, Saving Schemes, Exchange offers, Jewellery Repairs, Easy EMI and Jewellery Insurance. {branch.storeName} is a trademark today, in its segment and for its customers.
                <br />
                Our honest and transparent dealings has own confidence, trust and a special place in the hearts of customers.
                <br />
                <h2>Our Vision</h2>


                We have a simple vision to serve our customer and gain their credibility.
                <h2>Our Mission </h2>


                Our mission is to treat all customers at par, be transparent and honest.


              </p>

            </div>
          </div>
          <div className="col-md-12 col-lg-6 ">
            <img
              src={AboutBack}
              alt="About-Img"
              tw="absolute right-0 top-0 -z-10 hidden lg:block w-5/12"
            />
            <img
              src={AboutImg}
              alt="About-Img"
              className="img-fluid m-auto"
              tw="transform transition duration-500 hover:scale-110"
            />
          </div>
        </div>


      </div>
    </>
  );
}
export default memo(AboutUs);
